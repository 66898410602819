.wdt-loading-screen {
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: fixed;
	z-index: 999998;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
}

.wdt-loading-phrases {
	width: 300px;
	margin: 0 auto;
	height: 106px;
	overflow: hidden;
	position: relative;
	padding: 10px 0;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}

.wdt-loading-phrase-category {
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}

.wdt-loading-phrases:before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(50%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}

.wdt-loading-phrase {
	padding-left: 30px;
	position: relative;
	transform: translateY(30px);
	line-height: 30px;
	height: 30px;
}

.wdt-loading-phrase:before {
	content: " ";
	width: 18px;
	height: 18px;
	position: absolute;
	top: 6px;
	left: 0;
	border: 1px solid #ccc;
	border-radius: 50%;
}

.wdt-loading-phrase:after {
	opacity: 0;
	height: 11px;
	width: 7px;
	-webkit-transform-origin: left top;
	-moz-transform-origin: left top;
	-ms-transform-origin: left top;
	-o-transform-origin: left top;
	transform-origin: left top;
	border-right: 3px solid #2EB150;
	border-top: 3px solid #2EB150;
	border-radius: 2.5px !important;
	content: '';
	left: 3px;
	top: 16px;
	position: absolute;
	-webkit-transform: scaleX(-1) rotate(135deg);
	-moz-transform: scaleX(-1) rotate(135deg);
	-ms-transform: scaleX(-1) rotate(135deg);
	-o-transform: scaleX(-1) rotate(135deg);
	transform: scaleX(-1) rotate(135deg);
}

.wdt-loading-phrase.wdt-checked:after {
	-webkit-animation-delay: 100ms;
	-moz-animation-delay: 100ms;
	animation-delay: 100ms;
	-webkit-animation-duration: 200ms;
	-moz-animation-duration: 200ms;
	animation-duration: 200ms;
	-webkit-animation-timing-function: ease;
	-moz-animation-timing-function: ease;
	animation-timing-function: ease;
	-webkit-animation-name: checkmark;
	-moz-animation-name: checkmark;
	animation-name: checkmark;
	-webkit-transform: scaleX(-1) rotate(135deg);
	-moz-transform: scaleX(-1) rotate(135deg);
	-ms-transform: scaleX(-1) rotate(135deg);
	-o-transform: scaleX(-1) rotate(135deg);
	transform: scaleX(-1) rotate(135deg);
	-webkit-animation-fill-mode: forwards;
	-moz-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@-webkit-keyframes checkmark {
	0% {
		height: 0;
		width: 7px;
		opacity: 1;
	}
	20% {
		height: 2px;
		width: 7px;
		opacity: 1;
	}
	40% {
		height: 5px;
		width: 7px;
		opacity: 1;
	}
	100% {
		height: 11px;
		width: 7px;
		opacity: 1;
	}
}

@-moz-keyframes checkmark {
	0% {
		height: 0;
		width: 7px;
		opacity: 1;
	}
	20% {
		height: 2px;
		width: 7px;
		opacity: 1;
	}
	40% {
		height: 5px;
		width: 7px;
		opacity: 1;
	}
	100% {
		height: 11px;
		width: 7px;
		opacity: 1;
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 7px;
		opacity: 1;
	}
	20% {
		height: 2px;
		width: 7px;
		opacity: 1;
	}
	40% {
		height: 5px;
		width: 7px;
		opacity: 1;
	}
	100% {
		height: 11px;
		width: 7px;
		opacity: 1;
	}
}